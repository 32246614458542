import React, { Children } from "react"
import classNames from "classnames"

export const HeroTitle = ({ children }) => (
  <h2 className="is-size-1 is-size-5-mobile has-text-weight-900 has-text-white is-uppercase mb-1">
    {children}
  </h2>
)

export const HeroSubTitle = ({ children }) => (
  <p className="is-size-4 is-size-6-mobile has-text-white mb-1">{children}</p>
)

export const HeroImg = ({ children }) => (
  <figure className="image mb-2">{Children.toArray(children)}</figure>
)

export const HeroBody = ({ children }) => (
  <div className="hero-body is-flex align-items-center">
    <div className="container">{children}</div>
  </div>
)

export const HeroHead = ({ children }) => (
  <div className="hero-head">{Children.toArray(children)}</div>
)

export const Hero = ({ children, fh }) => {
  return (
    <section
      id="home"
      className={classNames("hero is-medium has-bg-000 shadow-inset", {
        "is-fullheight-desktop": fh,
      })}
    >
      {children}
    </section>
  )
}
